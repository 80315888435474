
@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/font/Pretendard-Black.woff') format('woff');
  font-weight: 900;
}

/* 기본 폰트 설정 */
body {
  font-family: 'Pretendard', sans-serif;
}